//会員用ナビ
function createMemberNavi() {
  const target = $('.c-memberNavi__anchor');
  const allDropDown = $('.c-memberNavi__dropDown');
  const targetClass = 'is-memberNaviOpen';
  const flagClass = 'is-memberDropdownOpen';

  //Dropdown機能
  target.on('click', function(e) {
    if($(this).attr('href').length == 0) {
      e.preventDefault();
      let targetDropdown = $(this).next('.c-memberNavi__dropDown');

      if ($(this).hasClass(targetClass)) {
        //対象が開いている状態
        $(this).removeClass(targetClass);
        allDropDown.removeClass(flagClass);
        targetDropdown.slideUp();
      } else {
        //対象が開いていない状態
        target.removeClass(targetClass);
        $(this).addClass(targetClass);
        allDropDown.removeClass(flagClass).fadeOut();
        targetDropdown.addClass(flagClass).slideDown();
      }
    }
  });

  //会員ナビでidから始まるリンクを押した場合（ページ内アンカー）
  $('.c-memberNavi .c-memberNavi__dropDown-anchor[href^="#"]').on('click', function(e) {
    e.preventDefault();

    allDropDown.removeClass(flagClass).slideUp();
    target.removeClass(targetClass);

    //通常のページ内アンカー処理
    var speed = 1000;
    var hh = $(".l-header__inner").innerHeight() + $('.c-memberNavi').innerHeight();
    var href= $(this).attr("href");
    var $target = $(href == "#" || href == "" ? 'html' : href);

    if ($target.length) {
      var pos = $target.offset().top - hh;
      $("html, body").animate({scrollTop:pos}, speed, "swing");
    }

    return false;
  });
}