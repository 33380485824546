$(document).ready(function() {
	// #site-loginフォームの値をajaxで送信し認証
	$('#site-login').ajaxForm({
		dataType: 'json',

		success: function(auth_result) {
			if(auth_result){
				location.href ="/";
			}else{
				alert("ログインに失敗しました。\n再度ご確認いただきますようお願いいたします。");
			}
		}
	});

	//ログイン状態を取得し、ボタンの表示と非表示
	$.ajax({
		type: 'post', // HTTPメソッド（CodeIgniterだとgetは捨てられる）
		url: '/auth/get_login_status_json', //リクエストの送り先URL
		dataType: 'json', //サーバからくるデータの形式を指定

		//リクエストが成功したらこの関数を実行
    //ログインによる出しわけはCSSで制御
		success: function(user_login_status){
			if(user_login_status){
        $('body').attr('data-login','true');
				// $('a[href*="/member/pdf/"]').attr({target:"_blank"})
			}else{
        $('body').attr('data-login','false');
			}
		}

	});
});
