//hamburger menu
function createHamburgerMenu () {

  var $win = $(window);
  var ww = window.innerWidth;
  var currentWidth = window.innerWidth;
  var breakpoint = 767.5;

  // ---  横幅が変動した時に実行
//  $win.on('load', function () {
    checkGnavWidth();
//  });

  window.addEventListener("resize", function () {
    // ウインドウ横幅が変わっていない場合
    if ( currentWidth == window.innerWidth ) {
      return false;
    }
    checkGnavWidth();
    currentWidth = window.innerWidth; // 横幅を更新

  });

  // -- toggle action
  $('#toggle').on('click', function () {
    $(this).toggleClass('is-toggleActive');
    $('html').toggleClass('open');

    if ( $(this).hasClass('is-toggleActive') ) {
      $('#gnavi').addClass('is-navOpen');
    } else {
      $('#gnavi').removeClass('is-navOpen');
    }

    $('#gnavi').slideToggle( function () { // iOSバグ対策
      if( $(this).hasClass('is-navOpen') ) {
        $(this).css('display','block');
      } else {
        //監視解除
        $(this).css('display','none');
      }
    });

    //スマホでスクロールした際に上下のバーが出てきて、高さが変わるのに対応する。
    if( ww <= breakpoint ) {
      var windowInnerHeight = window.innerHeight;
      var headerHeight = $('.l-header__inner').innerHeight(); //variable.scssの「$headerHeight-sp」と同じ値を取得。
      $('.l-navigation__inner').css('height', windowInnerHeight - headerHeight);
    }

  });


  function checkGnavWidth () {
    ww = window.innerWidth;
    $("[data-js='dropDown'] > a").off();
    if( ww > breakpoint ) {
      $('#gnavi').css('display','block');
      $('.l-navigation__inner').css('height', '100%');
    } else {
      $('#gnavi').css('display','none');
      $('#toggle').removeClass('is-toggleActive');
      $('html').removeClass('open');
    }
    globalNavInit();
    anchorLift();
  }

  // --- リサイズ時の初期化
  function globalNavInit () {
    if( ww >= breakpoint ) {
      //PC挙動
      $("[data-js='dropDown'] > a").on('click', function(e){
        e.preventDefault();
        if ($(this).parent("[data-js='dropDown']").hasClass('isDropdownOpen')) {
          $(this).parent("[data-js='dropDown']").removeClass('isDropdownOpen');
        } else {
          $("[data-js='dropDown']").removeClass('isDropdownOpen');
          $(this).parent("[data-js='dropDown']").addClass('isDropdownOpen');
        }
      });
    } else {
      //ハンバーガーメニュー挙動
      $("[data-js='dropDown'] > a").on('click', function(e){
        e.preventDefault();
        if ($(this).parent("[data-js='dropDown']").hasClass('isDropdownOpen')) {
          $(this).parent("[data-js='dropDown']").removeClass('isDropdownOpen');
        } else {
          $(this).parent("[data-js='dropDown']").addClass('isDropdownOpen');
        }
      });
    }
  }

  //スマホでページ内アンカーリンクをクリックした場合、メニューをしまう
  function anchorLift() {
    let target = $('.l-navigation__item > a[href*="#"] , .l-navigation__item .l-dropDown > li > a[href*="#');
    target.off();
    clickAnchorScroll();
    target.on('click', function() {
      if( ww <= breakpoint ) {
        $('html').removeClass('open');
        $('#toggle').removeClass('is-toggleActive');
        $('#gnavi').removeClass('is-navOpen').slideUp();
      }
    });
  }


}
